import { add, compare, decrement, increment, integerDivide, modulo, multiply, power, subtract, zero } from './api';
export var calculator = {
  add: add,
  compare: compare,
  decrement: decrement,
  increment: increment,
  integerDivide: integerDivide,
  modulo: modulo,
  multiply: multiply,
  power: power,
  subtract: subtract,
  zero: zero
};