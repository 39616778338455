function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { equal } from '../utils';
import { normalizeScale } from './normalizeScale';
export function haveSameAmount(calculator) {
  var normalizeFn = normalizeScale(calculator);
  var equalFn = equal(calculator);
  return function _haveSameAmount() {
    for (var _len = arguments.length, _ref = new Array(_len), _key = 0; _key < _len; _key++) {
      _ref[_key] = arguments[_key];
    }
    var dineroObjects = _ref[0];
    var _normalizeFn = normalizeFn(dineroObjects),
      _normalizeFn2 = _toArray(_normalizeFn),
      firstDinero = _normalizeFn2[0],
      otherDineros = _normalizeFn2.slice(1);
    var _firstDinero$toJSON = firstDinero.toJSON(),
      comparatorAmount = _firstDinero$toJSON.amount;
    return otherDineros.every(function (d) {
      var _d$toJSON = d.toJSON(),
        subjectAmount = _d$toJSON.amount;
      return equalFn(subjectAmount, comparatorAmount);
    });
  };
}