function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
/* eslint-disable functional/no-expression-statement */
import { UNEQUAL_CURRENCIES_MESSAGE } from '../checks';
import { assert } from '../helpers';
import { greaterThan as gt } from '../utils';
import { haveSameCurrency } from './haveSameCurrency';
import { normalizeScale } from './normalizeScale';
function unsafeGreaterThan(calculator) {
  var greaterThanFn = gt(calculator);
  return function greaterThan() {
    for (var _len = arguments.length, _ref = new Array(_len), _key = 0; _key < _len; _key++) {
      _ref[_key] = arguments[_key];
    }
    var dineroObject = _ref[0],
      comparator = _ref[1];
    var dineroObjects = [dineroObject, comparator];
    var _dineroObjects$map = dineroObjects.map(function (d) {
        var _d$toJSON = d.toJSON(),
          amount = _d$toJSON.amount;
        return amount;
      }),
      _dineroObjects$map2 = _slicedToArray(_dineroObjects$map, 2),
      subjectAmount = _dineroObjects$map2[0],
      comparatorAmount = _dineroObjects$map2[1];
    return greaterThanFn(subjectAmount, comparatorAmount);
  };
}
export function safeGreaterThan(calculator) {
  var normalizeFn = normalizeScale(calculator);
  var greaterThanFn = unsafeGreaterThan(calculator);
  return function greaterThan() {
    for (var _len2 = arguments.length, _ref2 = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      _ref2[_key2] = arguments[_key2];
    }
    var dineroObject = _ref2[0],
      comparator = _ref2[1];
    var condition = haveSameCurrency([dineroObject, comparator]);
    assert(condition, UNEQUAL_CURRENCIES_MESSAGE);
    var _normalizeFn = normalizeFn([dineroObject, comparator]),
      _normalizeFn2 = _slicedToArray(_normalizeFn, 2),
      subjectAmount = _normalizeFn2[0],
      comparatorAmount = _normalizeFn2[1];
    return greaterThanFn(subjectAmount, comparatorAmount);
  };
}