import { NON_DECIMAL_CURRENCY_MESSAGE } from '../checks';
import { assert } from '../helpers';
import { absolute, computeBase, equal, isArray, lessThan } from '../utils';
import { toUnits } from './toUnits';
export function toDecimal(calculator) {
  var toUnitsFn = toUnits(calculator);
  var computeBaseFn = computeBase(calculator);
  var equalFn = equal(calculator);
  return function toDecimalFn() {
    for (var _len = arguments.length, _ref = new Array(_len), _key = 0; _key < _len; _key++) {
      _ref[_key] = arguments[_key];
    }
    var dineroObject = _ref[0],
      transformer = _ref[1];
    var _dineroObject$toJSON = dineroObject.toJSON(),
      currency = _dineroObject$toJSON.currency,
      scale = _dineroObject$toJSON.scale;
    var base = computeBaseFn(currency.base);
    var zero = calculator.zero();
    var ten = new Array(10).fill(null).reduce(calculator.increment, zero);
    var isMultiBase = isArray(currency.base);
    var isBaseTen = equalFn(calculator.modulo(base, ten), zero);
    var isDecimal = !isMultiBase && isBaseTen;

    // eslint-disable-next-line functional/no-expression-statement
    assert(isDecimal, NON_DECIMAL_CURRENCY_MESSAGE);
    var units = toUnitsFn(dineroObject);
    var getDecimalFn = getDecimal(calculator, dineroObject.formatter);
    var value = getDecimalFn(units, scale);
    if (!transformer) {
      return value;
    }
    return transformer({
      value: value,
      currency: currency
    });
  };
}
function getDecimal(calculator, formatter) {
  var absoluteFn = absolute(calculator);
  var equalFn = equal(calculator);
  var lessThanFn = lessThan(calculator);
  var zero = calculator.zero();
  return function (units, scale) {
    var whole = formatter.toString(units[0]);
    var fractional = formatter.toString(absoluteFn(units[1]));
    var scaleNumber = formatter.toNumber(scale);
    var decimal = "".concat(whole, ".").concat(fractional.padStart(scaleNumber, '0'));
    var leadsWithZero = equalFn(units[0], zero);
    var isNegative = lessThanFn(units[1], zero);

    // A leading negative zero is a special case because the `toString`
    // formatter won't preserve its negative sign (since 0 === -0).
    return leadsWithZero && isNegative ? "-".concat(decimal) : decimal;
  };
}