/* eslint-disable functional/no-let, functional/no-loop-statement, functional/no-expression-statement */

import { equal } from './equal';
export function countTrailingZeros(calculator) {
  var equalFn = equal(calculator);
  return function (input, base) {
    var zero = calculator.zero();
    if (equalFn(zero, input)) {
      return calculator.zero();
    }
    var i = zero;
    var temp = input;
    while (equalFn(calculator.modulo(temp, base), zero)) {
      temp = calculator.integerDivide(temp, base);
      i = calculator.increment(i);
    }
    return i;
  };
}