function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
import { isArray, getDivisors } from '../utils';
export function toUnits(calculator) {
  var getDivisorsFn = getDivisors(calculator);
  return function toUnitsFn() {
    for (var _len = arguments.length, _ref = new Array(_len), _key = 0; _key < _len; _key++) {
      _ref[_key] = arguments[_key];
    }
    var dineroObject = _ref[0],
      transformer = _ref[1];
    var _dineroObject$toJSON = dineroObject.toJSON(),
      amount = _dineroObject$toJSON.amount,
      currency = _dineroObject$toJSON.currency,
      scale = _dineroObject$toJSON.scale;
    var power = calculator.power,
      integerDivide = calculator.integerDivide,
      modulo = calculator.modulo;
    var bases = isArray(currency.base) ? currency.base : [currency.base];
    var divisors = getDivisorsFn(bases.map(function (base) {
      return power(base, scale);
    }));
    var value = divisors.reduce(function (amounts, divisor, index) {
      var amountLeft = amounts[index];
      var quotient = integerDivide(amountLeft, divisor);
      var remainder = modulo(amountLeft, divisor);
      return [].concat(_toConsumableArray(amounts.filter(function (_, i) {
        return i !== index;
      })), [quotient, remainder]);
    }, [amount]);
    if (!transformer) {
      return value;
    }
    return transformer({
      value: value,
      currency: currency
    });
  };
}