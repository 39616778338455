/* eslint-disable functional/no-expression-statement */
import { INVALID_RATIOS_MESSAGE } from '../checks';
import { assert } from '../helpers';
import { distribute, equal, getAmountAndScale, greaterThan, greaterThanOrEqual, maximum } from '../utils';
import { transformScale } from './transformScale';
function unsafeAllocate(calculator) {
  return function allocate() {
    for (var _len = arguments.length, _ref = new Array(_len), _key = 0; _key < _len; _key++) {
      _ref[_key] = arguments[_key];
    }
    var dineroObject = _ref[0],
      ratios = _ref[1];
    var _dineroObject$toJSON = dineroObject.toJSON(),
      amount = _dineroObject$toJSON.amount,
      currency = _dineroObject$toJSON.currency,
      scale = _dineroObject$toJSON.scale;
    var distributeFn = distribute(calculator);
    var shares = distributeFn(amount, ratios.map(function (ratio) {
      return ratio.amount;
    }));
    return shares.map(function (share) {
      return dineroObject.create({
        amount: share,
        currency: currency,
        scale: scale
      });
    });
  };
}
export function safeAllocate(calculator) {
  var allocateFn = unsafeAllocate(calculator);
  var greaterThanOrEqualFn = greaterThanOrEqual(calculator);
  var greaterThanFn = greaterThan(calculator);
  var convertScaleFn = transformScale(calculator);
  var maximumFn = maximum(calculator);
  var equalFn = equal(calculator);
  var zero = calculator.zero();
  var ten = new Array(10).fill(null).reduce(calculator.increment, zero);
  return function allocate() {
    for (var _len2 = arguments.length, _ref2 = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      _ref2[_key2] = arguments[_key2];
    }
    var dineroObject = _ref2[0],
      ratios = _ref2[1];
    var hasRatios = ratios.length > 0;
    var scaledRatios = ratios.map(function (ratio) {
      return getAmountAndScale(ratio, zero);
    });
    var highestRatioScale = hasRatios ? maximumFn(scaledRatios.map(function (_ref3) {
      var scale = _ref3.scale;
      return scale;
    })) : zero;
    var normalizedRatios = scaledRatios.map(function (_ref4) {
      var amount = _ref4.amount,
        scale = _ref4.scale;
      var factor = equalFn(scale, highestRatioScale) ? zero : calculator.subtract(highestRatioScale, scale);
      return {
        amount: calculator.multiply(amount, calculator.power(ten, factor)),
        scale: scale
      };
    });
    var hasOnlyPositiveRatios = normalizedRatios.every(function (_ref5) {
      var amount = _ref5.amount;
      return greaterThanOrEqualFn(amount, zero);
    });
    var hasOneNonZeroRatio = normalizedRatios.some(function (_ref6) {
      var amount = _ref6.amount;
      return greaterThanFn(amount, zero);
    });
    var condition = hasRatios && hasOnlyPositiveRatios && hasOneNonZeroRatio;
    assert(condition, INVALID_RATIOS_MESSAGE);
    var _dineroObject$toJSON2 = dineroObject.toJSON(),
      scale = _dineroObject$toJSON2.scale;
    var newScale = calculator.add(scale, highestRatioScale);
    return allocateFn(convertScaleFn(dineroObject, newScale), normalizedRatios);
  };
}