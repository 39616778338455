/* eslint-disable functional/no-mixed-type, functional/no-return-void, functional/no-expression-statement */

export function createDinero(_ref) {
  var calculator = _ref.calculator,
    onCreate = _ref.onCreate,
    _ref$formatter = _ref.formatter,
    formatter = _ref$formatter === void 0 ? {
      toNumber: Number,
      toString: String
    } : _ref$formatter;
  return function dinero(_ref2) {
    var amount = _ref2.amount,
      _ref2$currency = _ref2.currency,
      code = _ref2$currency.code,
      base = _ref2$currency.base,
      exponent = _ref2$currency.exponent,
      _ref2$scale = _ref2.scale,
      scale = _ref2$scale === void 0 ? exponent : _ref2$scale;
    var currency = {
      code: code,
      base: base,
      exponent: exponent
    };
    onCreate === null || onCreate === void 0 ? void 0 : onCreate({
      amount: amount,
      currency: currency,
      scale: scale
    });
    return {
      calculator: calculator,
      formatter: formatter,
      create: dinero,
      toJSON: function toJSON() {
        return {
          amount: amount,
          currency: currency,
          scale: scale
        };
      }
    };
  };
}